









































import Component from 'vue-class-component'
import { Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import CollectionHeading from '@/components/CollectionHeading.vue'
import ScrollerHorizontal from '@/components/ScrollerHorizontal.vue'
import VueRouter from 'vue-router'
import BulletsPagination from '@/components/BulletsPagination.vue'
import ScrollerElement from '@/components/ScrollerElement.vue'
import EventType from '@/constants/event-type'
import { resolveSlug } from '@/utils/alias-resolver'

const { isNavigationFailure, NavigationFailureType } = VueRouter

@Component({
    components: {
        ScrollerElement,
        CollectionHeading,
        ScrollerHorizontal,
        BulletsPagination
    }
})
export default class CollectionCarousel extends Vue {
    @Prop() walker!: SlideWalker
    @Prop() y!: number
    @Prop() endDrag!: Function

    @Ref() scrollerHorizontal!: ScrollerHorizontal

    currentSlideIndex = this.$route.meta?.index

    resolveWalkerSlug(walker: SlideWalker | PresentationWalker) {
        return resolveSlug(walker)
    }

    updateRoute() {
        const child = this.walker.children[this.currentSlideIndex]

        if (!child) return

        const pathElements = this.$route.path.split('/')
        pathElements.splice(-1, 1, resolveSlug(child))

        const path = pathElements.join('/')

        this.$router.push(path).catch(failure => {
            if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
                return
            }
        })
    }

    onChildCurrentSlideIndexChange(event: { currentSlideIndex: number }) {
        this.currentSlideIndex = event.currentSlideIndex
    }

    onScrollerHorizontalTweenComplete() {
        this.updateRoute()
    }

    @Watch('$route')
    onRouteChange() {
        this.currentSlideIndex = this.$route.meta?.index
    }

    onCurrentSlideAssetsLoaded() {
        this.$emit(EventType.ASSETS_LOADED)
    }

    onCollectionHeadingTextClick({ indexX }: { indexX: number }) {
        this.scrollerHorizontal.gotoSlide(indexX)
    }

    onBulletsPaginationGotoSlide({ index }: { index: number }) {
        this.scrollerHorizontal.gotoSlide(index)
    }
}
