













import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import RootSlide from '@/mixins/RootSlide'
import ScrollerVertical from '@/components/ScrollerVertical.vue'
import SlideWalkerLoader from '@/mixins/SlideWalkerLoader'
import CollectionCarousel from '@/components/CollectionCarousel.vue'
import EventType from '@/constants/event-type'

@Component({
    components: {
        ScrollerVertical,
        CollectionCarousel
    }
})
export default class SlideCollectionsCarousel extends mixins(RootSlide, SlideWalkerLoader) {
    dispatchAssetsLoaded() {
        this.$emit(EventType.ASSETS_LOADED)
    }

    onRouterViewAssetsLoaded() {
        this.dispatchAssetsLoaded()
    }

    onCarouselAssetsLoaded() {
        this.dispatchAssetsLoaded()
    }
}
