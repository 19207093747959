























import Component, { mixins } from 'vue-class-component'
import Slide from '../mixins/Slide'
import MediaContent from '@/components/MediaContent.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import BasicIntersectionObservable from '@/mixins/BasicIntersectionObservable'
import ArrowDownNextSlide from '@/components/ArrowDownNextSlide.vue'
import Color from '@/constants/color'
import { mediaIsMin } from '@/utils/media'
import { Getter } from 'vuex-class'

@Component({
    components: {
        ScrollerElement,
        MediaContent,
        ArrowDownNextSlide
    }
})
export default class CollectionHeading extends mixins(Slide, BasicIntersectionObservable) {
    color = Color.LIGHT
    mediaPlayTimeout: number | undefined

    @Getter navigationIsAllowed!: boolean

    $refs!: {
        media: MediaContent
        text: HTMLElement
        overTitle: HTMLElement
        title: HTMLElement
    }

    get walkerItem(): SlideWalkerItemCollection {
        return this.walker.item as SlideWalkerItemCollection
    }

    getOffset() {
        if (!this.navigationIsAllowed) return 0

        return mediaIsMin('lg') ? 150 : 72
    }

    mounted() {
        // TODO: listen to media content asset load
        this.onAssetsLoaded()
    }

    shouldAppear(): boolean {
        return this.isCurrent || this.indexX === this.currentSlideIndexX + 1
    }

    populateAppear(timeline: GSAPTimeline) {
        if (this.isCurrent) {
            if (this.$refs.media) {
                timeline.from(
                    this.$refs.media.$el,
                    {
                        opacity: 0.8,
                        scale: 1.2,
                        duration: 2.4,
                        ease: 'power2'
                    },
                    0.1
                )
            }
        }

        timeline.from(
            [this.$refs.overTitle, this.$refs.title],
            {
                opacity: 0,
                y: 80,
                stagger: 0.1,
                duration: 1,
                ease: 'power3'
            },
            0.4 + (this.isCurrent ? 0.1 : 0.3)
        )
    }

    populateEnterX({ timeline }: ScrollerElementAnimationEvent) {
        const parentRect = this.$el.getBoundingClientRect()
        const textRect = this.$refs.text.getBoundingClientRect()

        timeline.fromTo(
            this.$refs.text,
            {
                x: parentRect.x - textRect.x - this.getOffset(),
                opacity: 0.16
            },
            {
                x: 0,
                duration: 1,
                opacity: 1
            },
            0
        )
    }

    populateLeaveX({ timeline }: ScrollerElementAnimationEvent) {
        const text = this.$refs.text as HTMLElement

        timeline.fromTo(
            text,
            {
                x: 0,
                opacity: 1
            },
            {
                x: this.getOffset(),
                opacity: 1,
                duration: 1,
                immediateRender: false
            },
            0
        )
    }

    /**
     * @override
     */
    onObservableEnter() {
        // Prevent triggering play while sliding too quickly
        this.mediaPlayTimeout = window.setTimeout(() => {
            this.$refs.media?.play()
        }, 100)
    }

    /**
     * @override
     */
    onObservableLeave() {
        window.clearTimeout(this.mediaPlayTimeout)

        this.$refs.media?.pause()
    }

    onTextClick() {
        this.$emit('text-click', { indexX: this.indexX })
    }
}
