












import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

@Component
export default class BulletsPagination extends Vue {
    @Prop() items!: Array<unknown>
    @Prop({ default: 0 }) currentItemIndex!: number

    onButtonClick(index: number) {
        this.$emit('goto-slide', { index })
    }
}
